import React, { Fragment, useState, useEffect, useRef } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { Link, useLocation } from "react-router-dom";

const navigation = [
  {
    name: "Falcon",
    href: "#",
    submenu: [
      { name: "Boosters", href: "/falcon/boosters/" },
      { name: "Stats", href: "/falcon/stats/" },
    ],
  },
  {
    name: "Dragon",
    href: "#",
    submenu: [
      { name: "Dragons", href: "/dragon/spacecraft/" },
      { name: "Stats", href: "/dragon/stats/" },
    ],
  },
  {
    name: "Starship",
    href: "#",
    submenu: [
      { name: "Boosters", href: "/starship/boosters/" },
      { name: "Ships", href: "/starship/ships/" },
      { name: "Stats", href: "/starship/stats/" },
    ],
  },
  { name: "Launches", href: "/launches/" },
  {
    name: "More",
    href: "#",
    submenu: [
      { name: "Historical Launch Calendar", href: "/launch-calendar/" },
      { name: "Pads", href: "/pads/" },
      { name: "Recovery Zones", href: "/recoveryzones/" },
      { name: "Estimated Mass to Orbit", href: "/graphs/" },
    ],
  },
];

const classNames = (...classes) => classes.filter(Boolean).join(" ");

const openXLink = () => window.open("https://x.com/BoosterTracker", "_blank");

function Navbar() {
  const location = useLocation();
  const [hovered, setHovered] = useState(null);
  const [currentNavItem, setCurrentNavItem] = useState("");
  const [isScrolled, setIsScrolled] = useState(false);
  const navRef = useRef(null);

  // Reference to hold the current disclosure close function
  const closeMenuRef = useRef(null);

  // State to track if mobile menu is open
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Saved scroll position
  const scrollPositionRef = useRef(0);

  useEffect(() => {
    const path = location.pathname;
    const findNavItem = navigation.find(
      (nav) => path.startsWith(nav.href) || (nav.submenu && nav.submenu.some((sub) => path.startsWith(sub.href)))
    );
    setCurrentNavItem(findNavItem ? findNavItem.name : path === "/" ? "" : currentNavItem);
  }, [location, currentNavItem]);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Effect to handle body scrolling when mobile menu is open/closed
  useEffect(() => {
    if (isMobileMenuOpen) {
      // Save current scroll position
      scrollPositionRef.current = window.pageYOffset;

      // Disable scrolling
      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed";
      document.body.style.width = "100%";
      document.body.style.top = `-${scrollPositionRef.current}px`;
    } else {
      // Re-enable scrolling
      document.body.style.overflow = "";
      document.body.style.position = "";
      document.body.style.width = "";
      document.body.style.top = "";

      // Restore scroll position
      window.scrollTo(0, scrollPositionRef.current);
    }

    return () => {
      // Clean up in case component unmounts while menu is open
      document.body.style.overflow = "";
      document.body.style.position = "";
      document.body.style.width = "";
      document.body.style.top = "";
    };
  }, [isMobileMenuOpen]);

  // Add handler for clicks outside the navbar to close mobile menu
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        if (closeMenuRef.current) {
          closeMenuRef.current();
          setIsMobileMenuOpen(false);
        }
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    document.addEventListener("touchstart", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
      document.removeEventListener("touchstart", handleOutsideClick);
    };
  }, []);

  const handleMouseEnter = (name) => setHovered(name);
  const handleMouseLeave = () => setHovered(null);
  const handleNavClick = (name) => setCurrentNavItem(name);

  // Helper function to close mobile menu
  const closeMenu = () => {
    if (closeMenuRef.current) {
      closeMenuRef.current();
      setIsMobileMenuOpen(false);
    }
  };

  return (
    <header
      className={classNames(
        "sticky top-0 z-50 shadow-lg bg-[#1E2A38] backdrop-blur-sm",
        isScrolled ? "bg-opacity-70" : "bg-opacity-60"
      )}
      ref={navRef}
    >
      <Disclosure as="nav" defaultOpen={isMobileMenuOpen}>
        {({ open, close }) => {
          // Store the close function in our ref so we can access it elsewhere
          closeMenuRef.current = close;

          // Update mobile menu state without hooks
          if (open !== isMobileMenuOpen) {
            // Use setTimeout to avoid state updates during rendering
            setTimeout(() => setIsMobileMenuOpen(open), 0);
          }

          return (
            <>
              <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
                <div className="relative flex items-center justify-between h-16">
                  <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                    <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-300 hover:text-white hover:bg-white/10 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white transition-colors duration-200">
                      <span className="sr-only">Open main menu</span>
                      {open ? <XIcon className="block h-6 w-6" /> : <MenuIcon className="block h-6 w-6" />}
                    </Disclosure.Button>
                  </div>

                  <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                    <div className="flex-shrink-0 flex items-center">
                      <Link to="/" onClick={closeMenu}>
                        <img
                          className="block lg:hidden h-10 w-auto logo"
                          src="/booster-trackertext.png"
                          alt="Booster Tracker Logo"
                        />
                        <img
                          className="hidden lg:block h-10 w-auto logo"
                          src="/booster-tracker.png"
                          alt="Booster Tracker Logo"
                        />
                      </Link>
                    </div>
                    <div className="hidden sm:block sm:ml-6">
                      <div className="flex space-x-4 font-inter">
                        {navigation.map((item) =>
                          item.submenu ? (
                            <Menu
                              as="div"
                              key={item.name}
                              className="relative inline-block text-left perspective-1000"
                              onMouseEnter={() => handleMouseEnter(item.name)}
                              onMouseLeave={handleMouseLeave}
                            >
                              <Menu.Button
                                className={classNames(
                                  item.name === currentNavItem
                                    ? "text-white border-b-2 border-white"
                                    : "text-gray-300 hover:text-white",
                                  "group px-3 py-2 rounded-md text-sm font-medium flex items-center transition-all duration-200"
                                )}
                              >
                                {item.name}
                              </Menu.Button>
                              <Transition
                                as={Fragment}
                                show={hovered === item.name}
                                enter="transition ease-out duration-200"
                                enterFrom="opacity-0 translate-y-1"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in duration-150"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 translate-y-1"
                              >
                                <Menu.Items
                                  static
                                  className="fixed mt-2 w-48 rounded-md shadow-lg py-1 bg-white/90 backdrop-blur-sm ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
                                  style={{
                                    left: "auto",
                                    right: "auto",
                                  }}
                                >
                                  {item.submenu.map((subItem) => (
                                    <Menu.Item key={subItem.name}>
                                      {({ active }) => (
                                        <Link
                                          to={subItem.href}
                                          onClick={() => handleNavClick(item.name)}
                                          className={classNames(
                                            active ? "bg-gray-100/80 text-gray-900" : "text-gray-700",
                                            "block px-4 py-2 text-sm hover:bg-gray-100/80 hover:text-gray-900 transition-colors duration-150"
                                          )}
                                        >
                                          {subItem.name}
                                        </Link>
                                      )}
                                    </Menu.Item>
                                  ))}
                                </Menu.Items>
                              </Transition>
                            </Menu>
                          ) : (
                            <Link
                              key={item.name}
                              to={item.href}
                              onClick={() => handleNavClick(item.name)}
                              className={classNames(
                                item.name === currentNavItem
                                  ? "text-white border-b-2 border-white"
                                  : "text-gray-300 hover:text-white",
                                "px-3 py-2 rounded-md text-sm font-medium flex items-center transition-all duration-200"
                              )}
                            >
                              {item.name}
                            </Link>
                          )
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                    <button
                      type="button"
                      className="p-2 rounded-full bg-white/20 text-white hover:bg-white/30 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[#1E2A38] focus:ring-white transition-colors duration-200"
                      onClick={openXLink}
                    >
                      <img className="h-5 w-5" src="/x-logo-white.png" alt="X" />
                    </button>
                  </div>
                </div>
              </div>

              {/* Mobile menu with fixed position and specified transparency */}
              <Disclosure.Panel className="sm:hidden bg-[#1E2A38]/60 backdrop-blur-md border-t border-white/10 shadow-lg">
                <div className="px-2 pt-2 pb-3 space-y-1">
                  {navigation.map((item) =>
                    item.submenu ? (
                      <div key={item.name} className="space-y-1">
                        <div
                          className={classNames(
                            item.name === currentNavItem
                              ? "bg-white/10 text-white"
                              : "text-gray-300 hover:bg-white/10 hover:text-white",
                            "w-full text-left px-3 py-2 rounded-md text-base font-medium flex items-center transition-colors duration-150"
                          )}
                        >
                          {item.name}
                        </div>

                        <div className="pl-6 space-y-1">
                          {item.submenu.map((subItem) => (
                            <Link
                              key={subItem.name}
                              to={subItem.href}
                              onClick={() => {
                                handleNavClick(item.name);
                                close();
                              }}
                              className={classNames(
                                location.pathname === subItem.href
                                  ? "bg-white/10 text-white"
                                  : "text-gray-300 hover:bg-white/10 hover:text-white",
                                "block px-3 py-2 rounded-md text-sm font-medium transition-colors duration-150"
                              )}
                            >
                              {subItem.name}
                            </Link>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <Link
                        key={item.name}
                        to={item.href}
                        onClick={() => {
                          handleNavClick(item.name);
                          close();
                        }}
                        className={classNames(
                          item.name === currentNavItem
                            ? "bg-white/10 text-white"
                            : "text-gray-300 hover:bg-white/10 hover:text-white",
                          "block px-3 py-2 rounded-md text-base font-medium flex items-center transition-colors duration-150"
                        )}
                      >
                        {item.name}
                      </Link>
                    )
                  )}
                </div>
              </Disclosure.Panel>
            </>
          );
        }}
      </Disclosure>
    </header>
  );
}

export default Navbar;
